// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cinematography-hirafi-js": () => import("./../../../src/pages/cinematography/hirafi.js" /* webpackChunkName: "component---src-pages-cinematography-hirafi-js" */),
  "component---src-pages-cinematography-js": () => import("./../../../src/pages/cinematography.js" /* webpackChunkName: "component---src-pages-cinematography-js" */),
  "component---src-pages-cinematography-service-technique-js": () => import("./../../../src/pages/cinematography/service-technique.js" /* webpackChunkName: "component---src-pages-cinematography-service-technique-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-designcode-artisans-js": () => import("./../../../src/pages/designcode/artisans.js" /* webpackChunkName: "component---src-pages-designcode-artisans-js" */),
  "component---src-pages-designcode-dirha-js": () => import("./../../../src/pages/designcode/dirha.js" /* webpackChunkName: "component---src-pages-designcode-dirha-js" */),
  "component---src-pages-designcode-js": () => import("./../../../src/pages/designcode.js" /* webpackChunkName: "component---src-pages-designcode-js" */),
  "component---src-pages-designcode-octrino-js": () => import("./../../../src/pages/designcode/Octrino.js" /* webpackChunkName: "component---src-pages-designcode-octrino-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */)
}

